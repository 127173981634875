import './App.css';
import './style/reset.scss'
import React from 'react'
import Route from './router'

function App() {
  return (
    <div className="App">
      <Route></Route>
    </div>
  );
}

export default App;
